import { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import classNames from 'classnames';

import { MENU_RICH_STYLE } from '@/app-configuration';
import { useSelectedRetailerSelector } from '@stores/locations/selectors';
import DealerService from '@services/dealerService';
import {
  Default,
  Mobile,
  Tablet,
  Desktop,
  LargerDesktop,
} from '@helpers/breakpoints';
import { Container } from '@components';
import { Menu } from './components';
import Logo from '@assets/logo.svg';
import SquareLogo from '@assets/logo-square.svg';
import LogoStatement from '@assets/for-earth-for-life.svg';
// import MobileLogo from '@assets/logos/kubotaLogoWide.png';
import MenuIcon from '@assets/icon/menu.svg';
import styles from './Nav.module.scss';

const Search = dynamic(() => import('@components/Search'), { ssr: false });

const dealerService = DealerService();

const MainNav = ({
  activeMenu,
  setActiveMenu,
  menuItems,
  headerClose,
  setHeaderValue,
  deviceType,
  dealer,
}) => (
  <ul className={styles.MainNav}>
    <li>
      <Link href="/" prefetch={false}>
        <a className={styles.Branding}>
          <Tablet>
            <SquareLogo className={styles.Logo} />
          </Tablet>
          <Desktop>
            <Logo className={styles.Logo} />
          </Desktop>
          <LargerDesktop>
            <LogoStatement className={styles.LogoStatement} />
          </LargerDesktop>
        </a>
      </Link>
    </li>
    {menuItems?.length > 0 && menuItems?.map((item, index) => (
      <li className={styles.MenuItem} key={`menu__item-${index}`}>
        <Menu
          title={item.menuDetails.title}
          activeKey={item.id}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          items={item.children}
          headerClose={headerClose}
          setHeaderValue={setHeaderValue}
          deviceType={deviceType}
          isMenu={item.isMenu}
          url={item.menuDetails.url}
          dealer={dealer}
        />
      </li>
    ))}
  </ul>
);

const MainRichNav = ({
  activeMenu,
  setActiveMenu,
  menuItems,
  headerClose,
  setHeaderValue,
  deviceType,
  dealer,
}) => {
  const { locale } = useRouter();

  return (
    <>
      {menuItems?.length > 0 && menuItems?.map((menu, index) => (
        <ul className={styles.RichMainNav} key={`menu-${index}`}>
          <li>
            <Link href="/" prefetch={false}>
              <a className={styles.Branding}>
                <Tablet>
                  {index === 0 && (
                    <>
                      <LogoStatement className={styles.LogoStatement} />
                    </>
                  )}
                  {index === 1 && (
                    <SquareLogo className={styles.Logo} />
                  )}
                </Tablet>
                <Desktop>
                  {index === 0 && (
                    <>
                      <LogoStatement className={styles.LogoStatement} />
                      | {locale == 'fr'? 'Kubota Canada Ltee.' : 'Kubota Canada Ltd.'}
                    </>
                  )}
                  {index === 1 && (
                    <Logo className={styles.Logo} />
                  )}
                </Desktop>
              </a>
            </Link>
          </li>
          {menu?.children?.length > 0 && menu?.children?.map((item, index2) => (
            <li className={styles.MenuItem} key={`menu__item-${index2}`}>
              <Menu
                index={index}
                title={item.menuDetails.title}
                activeKey={item.id}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                items={item.children}
                headerClose={headerClose}
                setHeaderValue={setHeaderValue}
                deviceType={deviceType}
                isMenu={index%2==0? false : item.isMenu}
                url={item.menuDetails.url}
                dealer={dealer}
              />
            </li>
          ))}
          {index === 1 && (
            <Search />
          )}
        </ul>
      ))}
    </>
  );
};

const Nav = ({ menuItems, headerClose, setHeaderValue }) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [dealer, setDealer] = useState(null);

  const router = useRouter();
  const myDealer = useSelector(useSelectedRetailerSelector);

  useEffect(async () => {
    const getDealerInfo = async () => {
      if (myDealer?.code) {
        try {
          const dealerInfo = await dealerService.getDealerInfo({ dealerCode: myDealer?.code });
          if (dealerInfo && !isEqual(dealerInfo[0], dealer)) {
            setDealer(dealerInfo[0]);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    getDealerInfo();
  }, [myDealer?.code]);

  useEffect(() => {
    const handleRouteChange = () => {
      setActiveMenu(null);
      setIsMobileOpen(false)
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // useEffect(() => {
  //   if (!activeMenu) return;

  //   if (!isUtilityItemOpen && isMobileOpen) {
  //     setIsMobileOpen(false);
  //   }
  // }, [activeMenu, isUtilityItemOpen, isMobileOpen]);

  useEffect(()=>{
    if (isMobileOpen) {
      document.body.className = 'fixed-Position';
    } else {
      document.body.className = '';
    }
  }, [isMobileOpen])

  const isUtilityItemOpen = !menuItems?.some(
    ({ id }) => parseInt(id) === activeMenu,
  );

  const useOutsideMenuCloser = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActiveMenu(null);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideMenuCloser(wrapperRef);

  const handleMobileToggle = () => {
    if (isUtilityItemOpen) {
      setActiveMenu(null);
    }
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <nav ref={wrapperRef} className={classNames(styles.Navbar, {
      [styles.RichNavbar]: MENU_RICH_STYLE,
    })}>
      <Container className={classNames(styles.Container, {
        [styles.RichContainer]: MENU_RICH_STYLE,
      })}>
        <Default>
          {MENU_RICH_STYLE ? (
            <MainRichNav
              menuItems={menuItems}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              headerClose={headerClose}
              setHeaderValue={setHeaderValue}
              deviceType="default"
              dealer={dealer}
            />
          ) : (
            <MainNav
              menuItems={menuItems}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              headerClose={headerClose}
              setHeaderValue={setHeaderValue}
              deviceType="default"
              dealer={dealer}
            />
          )}
        </Default>
        <Mobile>
          <div className={styles.MobileTopNav}>
            <Link href="/" prefetch={false}>
              <a className={styles.Branding}>
                <img src="/assets/logos/kubota-logo-wide.png" alt="For Earth For Life" className={styles.Logo} />
              </a>
            </Link>
            <button
              className={styles.MobileToggle}
              onClick={handleMobileToggle}
            >
              <MenuIcon className={styles.Icon} />
              Menu
            </button>
          </div>

          <AnimatePresence>
            <motion.div
              className={styles.MobileNav}
              initial={{ opacity: 0, visibility: 'hidden' }}
              animate={{ opacity: isMobileOpen ? 1 : 0, visibility: isMobileOpen ? 'visible' : 'hidden' }}
              exit={{ opacity: 0, visibility: 'hidden' }}
            >
              <>
                {MENU_RICH_STYLE ? (
                  <>
                    <Search />
                    {menuItems?.length > 0 && (
                      <ul className={styles.MainNav}>
                        {menuItems?.slice().reverse().map((menu, index) => (
                          menu?.children?.length > 0 && menu?.children?.map((item) => (
                            <li className={styles.MenuItem} key={`menu__item-${item.id}`}>
                              <Menu
                                title={item.menuDetails.title}
                                activeKey={item.id}
                                activeMenu={activeMenu}
                                setActiveMenu={setActiveMenu}
                                items={item.children}
                                headerClose={headerClose}
                                setHeaderValue={setHeaderValue}
                                deviceType="mobile"
                                isMenu={item.isMenu}
                                url={item.menuDetails.url}
                                dealer={dealer}
                              />
                            </li>
                        ))))}
                      </ul>
                    )}
                  </>
                ) : (
                  <>
                    {menuItems?.length > 0 && (
                      <ul className={styles.MainNav}>
                        {menuItems?.map((menu, index) => (
                          <li className={styles.MenuItem} key={`menu__item-${index}`}>
                            <Menu
                              title={menu.menuDetails.title}
                              activeKey={menu.id}
                              activeMenu={activeMenu}
                              setActiveMenu={setActiveMenu}
                              items={menu.children}
                              headerClose={headerClose}
                              setHeaderValue={setHeaderValue}
                              deviceType="mobile"
                              isMenu={menu.isMenu}
                              url={menu.menuDetails.url}
                              dealer={dealer}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </>
            </motion.div>
          </AnimatePresence>
        </Mobile>
      </Container>
    </nav>
  );
};

export default Nav;
