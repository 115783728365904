import { i18n } from '@/next-i18next.config.js';
import API from '@utils/api';
import { getLanguageId } from '@helpers/language';
import { apiClient } from '@/http-client';

async function getPostPath(url) {
  if (!url) throw new Error('URL should be provided');

  try {
    const { data } = await apiClient.get(`/${url}`);
    return data.Path;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function getProductCode(url) {
  if (!url) throw new Error('URL should be provided');

  const productUrlArray = url.split('/');
  const productUrl = '/products/api' + (isNaN(productUrlArray[productUrlArray.length - 1]) ? `/products/${url}` : `/products/${url}/-1`);

  try {
    const { data } = await apiClient.get(productUrl);

    if (!data) {
      throw Error(`Invalid PRODUCT response: ${productUrl}`);
    }

    return data?.product?.ProductCode;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

const formatMenuItem = (item, locale = 'en') => {
  const lang = getLanguageId(locale);
  const defaultLang = getLanguageId('en');

  const { Id, IsMenu, Parent, Target, Children, MenuTexts, Name, Sequence } = item;

  const details = MenuTexts?.find(({ Language }) => Language === lang);

  let formattedItem;
  formattedItem = {
    id: Id,
    isMenu: IsMenu,
    sequence: Sequence,
    menuDetails: {
      id: details?.MenuId,
      title: details?.Title || Name,
      subtitle: details?.SubTitle,
      image: details?.ImageUrl,
      description: details?.Description,
      url: details?.Url ? `/${details.Url?.replace('api/', '')}` : null
    },
    target: Target,
    ...(Children?.length > 0 && {
      children: Children.filter(({ Status, VisibleDate, ExpiryDate, MenuTexts }) => {
        const currentDate = new Date();
        const startDate = new Date(VisibleDate);
        const endDate = new Date(ExpiryDate);
        return MenuTexts?.length > 0 && !!Status && currentDate > startDate && currentDate < endDate;
      })
        .sort((el1, el2) => {
          var keyA = el1.Sequence,
            keyB = el2.Sequence;
          // Compare the sequences
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        })
        .map((childItem) => {
          return formatMenuItem(childItem, locale);
        })
    }),
    ...(Parent && {
      parent: Parent
    })
  };
  /*
  //check if URL is not empty
  if (details?.Url?.split('/')[1]?.toLowerCase() === 'posts') {
    getPostPath(details.Url)
      .then((response) => {
        if (response !== undefined) formattedItem.slug = `/${response}`;
        return;
      })
      .catch((error) => {
        console.error(error);
        formattedItem.slug = null;
      });
  } else if (details?.Url?.split('/')[1]?.toLowerCase() === 'products') {
    getProductCode(details.Url)
      .then((response) => {
        if (response !== undefined) formattedItem.slug = `/products/${response}`;
        return;
      })
      .catch((error) => {
        console.error(error);
        formattedItem.slug = null;
      });
  }*/
  return formattedItem;
};

export default function MenuService(state) {
  const { locales } = i18n;
  /*
   * @desc
   * @return { menu } | State
   */

  const formatMenuData = (menu, locale) => {
    return menu
      .filter(({ Status, VisibleDate, ExpiryDate }) => {
        const currentDate = new Date();
        const startDate = new Date(VisibleDate);
        const endDate = new Date(ExpiryDate);
        return !!Status && currentDate > startDate && currentDate < endDate;
      })
      .sort((el1, el2) => {
        var keyA = el1.Sequence,
          keyB = el2.Sequence;
        // Compare the sequences
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })
      .map((menuItem) => formatMenuItem(menuItem, locale))
      .flat();
  };

  // Return a main site menu
  async function getMenuData() {
    const response = await API.post('/api/menu');

    return Object.fromEntries(locales.map((locale) => [locale, formatMenuData(response, locale)]));
  }

  return {
    getMenuData
  };
}
