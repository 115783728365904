import API from '@utils/api';
import { get, isEmpty } from 'lodash';

export default function LocationService() {
  async function getLocations(payload) {
    const response = await API.post('/api/locations', payload);

    const results = get(response, 'Response.Results.Data.Row', []);

    if (isEmpty(results)) {
      return [];
    }

    // Ensure `results` is treated as an array
    const locations = Array.isArray(results) ? results : Array.of(results);

    const processedLocations = await Promise.all(
      locations.map(async (location) => {
        const dealerInfo = await API.post('/api/dealerInfo', { dealerCode: location.DEALER_CODE });
        const dealerURLName = get(dealerInfo, '[0].dealerURLName', null);
        return {
          distance: location._DISTANCE,
          address1: location.DEALER_ADDRESS_1,
          address2: location.DEALER_ADDRESS_2,
          city: location.DEALER_CITY,
          code: location.DEALER_CODE,
          name: location.DEALER_NAME,
          lat: location.LATITUDE,
          lng: location.LONGITUDE,
          postal: location.DEALER_POSTAL_CODE,
          province: location.DEALER_PROV,
          phone: location.TELEPHONE,
          handle: dealerURLName,
        };
      })
    );
    return processedLocations;
  }

  return {
    getLocations
  };
}
