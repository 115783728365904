import { useUsedSelector } from '@stores/used/selectors';
// import { el } from 'date-fns/locale';
import { useSelector } from 'react-redux';

/**
 *  Return Filtered based on handle
 *
 * @return  {lang} String | en/fr
 */

export function useCategoryFilter(catId) {
  const categories = useSelector(useUsedSelector);
  let category = {};
  if (Array.isArray(categories) && categories) {
    category = categories.find((cat) => {
      return cat.id === parseInt(catId);
    });
  }
  return category;
}

/**
 *  Return Category List
 *
 * @return  { lang } String | en/fr
 */
export function useCategoryList(handle) {
  const categories = useSelector(useUsedSelector);
  let categoryList = [];
  if (Array.isArray(categories) && categories) {
    for (let i = 0; i < categories.length; i++) {
      categoryList.push({
        handle: categories[i].handle,
        name: categories[i].name
      });
    }
  }
  return categoryList;
}

/**
 *  Return Filtered products on handle
 *
 * @return  {lang} String | en/fr
 */
export function useProductFilter(catId, usedID) {
  const categories = useSelector(useUsedSelector);
  let product = null;
  if (Array.isArray(categories) && categories) {
    const category = categories.find((cat) => {
      return cat.id === parseInt(catId);
    })
    if (category) {
      product = category.products.find((product) => {
        return product.usedID === parseInt(usedID);
      });
    }
  }
  return product;
}

/**
 *  Return Used Product Total
 *
 * @return  Number
 */
export function useProductCount() {
  const categories = useSelector(useUsedSelector);
  let count = 0;
  if (Array.isArray(categories) && categories) {
    for (let i = 0; i < categories.length; i++) {
      for (let ii = 0; ii < categories[i].products.length; ii++) {
        if (
          /*!categories[i].products[ii].expiryDate.substr(6).includes('-') &&
          !categories[i].products[ii].solddateon.substr(6).includes('-') &&
          !categories[i].products[ii].modifiedDate.substr(6).includes('-')*/
          !checkValidDate(categories[i].products[ii].postDate, categories[i].products[ii].expiryDate,categories[i].products[ii].solddateon)
        )
          count++;
      }
    }
  }
  return count;
}

/**
 *  Convert Title to Handle
 *
 * @return  String
 */
export function handle(title) {
  return title
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}

/**
 *  Return Used Filtered Products
 *
 * @return  Number
 */
export function usedProductsFilters({ filters }) {
  const categories = useSelector(useUsedSelector);

  let count = 0;
  if (Array.isArray(categories) && categories) {
    for (let i = 0; i < categories.length; i++) {
      for (let ii = 0; ii < categories[i].products.length; ii++) {
        count++;
      }
    }
  }
  return count;
}


/**
 * Return Year options
 * @return  Array
 */
export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

export function usedYearFilters(currentProducts) {
  let parseDate = currentProducts
    ?.map((m) => ({
      /*
      label: isValidDate(m?.postDate) ? new Date(m?.postDate).getFullYear() : new Date(+m.postDate?.match(/[0-9]/g).join('')).getFullYear(),
      value: isValidDate(m?.postDate) ? new Date(m?.postDate).getFullYear() : new Date(+m.postDate.match(/[0-9]/g).join('')).getFullYear()
      */
     label: m?.year, value: m?.year
    }))
    .sort((a, b) => b - a);
  let sort = Object.values(parseDate.reduce((acc, cur) => Object.assign(acc, { [cur.label]: cur }), {}));
  return sort;
}

/**
 *  Return price option filter
 *
 * @return  Array
 */
export function usedPriceFilters(currentProducts, locale) {
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const maximum = Math.max.apply(
    Math,
    currentProducts?.map((o) => o.price)
  );
  if (maximum) {
    let max = maximum.toString();
    let spl = max.split('');
    let fistDigit = spl.shift();
    let lastDigits = spl.map((a) => '9').join('');
    let maxNumber = `${fistDigit}${lastDigits}`;
    let lastMaxNumber = Number(maxNumber);
    // let arr = range(0, maxNumber, 1000)
    let arr = range(0, maxNumber, (maxNumber / 10).toFixed(0));
    let option = arr.reduce((acc, val, i) => {
      acc.push({
        min: val,
        Checked: false,
        isCategory: false,
        max: arr.length - 1 == i ? lastMaxNumber : arr[i + 1] - 1,
        value: `$${val} CAD  - $${arr.length - 1 == i ? lastMaxNumber : arr[i + 1] - 1} CAD`,
        label: `${translatePrice(val, locale)}  - ${translatePrice(arr.length - 1 == i ? lastMaxNumber : arr[i + 1] - 1,locale)}`
      });
      return acc;
    }, []);
    return option;
  } else {
    return [];
  }
}

/**
 *  Return price option filter
 *
 * @return  Array
 */
export function usedPowerFilters(currentProducts, locale) {
  const range = [
    "0.00 – 19.99 HP",
    "20.00 – 39.99 HP",
    "40.00 – 59.99 HP",
    "60.00 – 79.99 HP",
    "80.00 – 99.99 HP",
    "100.00 – 119.99 HP",
    "120.00 + HP",
  ];
  return range.reduce((acc, val, i) => {
    acc.push({
      Checked: false,
      value: val,
      label: val,
    });
    return acc;
  }, []);
}

/** Return the translated price
 *
 * @param {*} price
 * @param {*} language
 * @returns
 */
export function translatePrice(price, language) {
  if (!price) return price;
  if (language == 'fr') {
    return price.toLocaleString('fr-CA', {
      currency: 'CAD',
      style: 'currency',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }
  return price.toLocaleString('en-CA', {
    currency: 'CAD',
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
}

export function checkInvalid(text) {
  const invalidStrings = ['N/A', '?', 'n/a', 'na', ''];
  return !invalidStrings.some(str => text?.includes(str)) ? text : '';
}

export function checkValidDate(postDate, expiryDate, soldDate){
  const regExp = /\(([^)]+)\)/;
  const current = new Date();
  expiryDate = expiryDate instanceof Date ?  expiryDate : new Date(parseInt(regExp.exec(expiryDate)[1]));
  //postDate = postDate instanceof Date ?  postDate : new Date(parseInt(regExp.exec(postDate)[1]));
  soldDate = soldDate instanceof Date ?  soldDate : new Date(parseInt(regExp.exec(soldDate)[1]));
  return (soldDate < new Date(0) )  &&  (current < expiryDate);
}

export function completeSellPrice(sellPrice, labourRate, labourHours, PDI, admin){
  return sellPrice + labourRate*labourHours+labourRate*PDI+admin;
}