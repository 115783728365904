import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { AnimatePresence, motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

import { Button } from '@components';
import { Default, Mobile, isMobile } from '@helpers/breakpoints';

import CloseIcon from '@assets/icon/close.svg';
import ArrowRight from '@assets/icon/arrow-right-l.svg';
import ArrowLeft from '@assets/icon/arrow-left-l.svg';
import styles from './Modal.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), {
  ssr: false
});

const Modal = ({
  show,
  onHide,
  title,
  children,
  className,
  data,
  displayFeatureText,
  type,
  solidBg,
  player,
  ParentData,
  sourceComponent,
  productCode
}) => {
  //type is equal to DecisionModal, ApplicationsModal, ContentModal, LightBox
  const isMobileOpen = isMobile();
  const [index, setIndex] = useState(0);

  const prev = () => {
    if (index != 0) {
      setIndex(index - 1);
    }
  };

  const next = () => {
    if (index != ParentData.length - 1) {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    let currentIndex;
    if (sourceComponent === 'Video') {
      currentIndex = ParentData?.findIndex((item) => item.ProductGalleryId === data.ProductGalleryId);
    } else if (sourceComponent === 'Feature') {
      currentIndex = ParentData?.findIndex((item) => item.Id === data.Id);
    } else if (sourceComponent === 'CompatibleImplement') {
      currentIndex = ParentData?.findIndex((item) => item.Product.ProductCode === data.ProductCode);
    }
  
    // Fallback to index 0 if the currentIndex is not found (-1)
    setIndex(currentIndex >= 0 ? currentIndex : 0);
  
    //if modal is open disable body scroll
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [show == true]);
  
  if(sourceComponent == 'CompatibleImplement'){
    console.log(ParentData);
  }
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className={styles.Modal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className={classNames(styles.Dimmer, {
              [styles.SolidBg]: solidBg == 'true' ? true : false
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onHide}
            data={data}
          />
          <div
            className={classNames(styles.Dialog, className, {
              [styles.DecisionModal]: type == 'DecisionModal' ? true : false,
              [styles.ApplicationsModal]: type == 'ApplicationsModal' ? true : false,
              [styles.ContentModal]: type == 'ContentModal' ? true : false,
              [styles.LightBoxModal]: type == 'LightBox' ? true : false
            })}
          >
            <div className={styles.Header}>
              {title && type !== 'LightBox' ? (
                <h4 className={styles.Title}>{title}</h4>
              ) : (
                <div></div>
              )}
              <button
                className={classNames(styles.CloseButton, type == 'LightBox' ? styles.NoTitle : null)}
                onClick={onHide}
              >
                <CloseIcon className={styles.CloseIcon} />
              </button>
            </div>

            {sourceComponent == 'Video' && show && player ? (
              <>
                <div className={styles.Body}>
                  <Default>
                    <ArrowLeft className={styles.Arrow} onClick={prev} />
                    <ReactPlayer url={show ? ParentData[index]?.VideoUrl : null} controls={true} width="100%" height="60vh" muted={true} playing={true} />
                    <ArrowRight className={styles.Arrow} onClick={next} />
                  </Default>
                  <Mobile>
                    <ReactPlayer url={show ? ParentData[index]?.VideoUrl : null} controls={true} width="100%" height="100%" muted={true} playing={true} />
                  </Mobile>
                </div>
                <Default>
                  <div className={styles.TextContent}>
                    <h4 className={styles.MSubTitle}>{ParentData[index]?.Description}</h4>
                    <p>
                      {ParentData[index]?.FeatureText?.FeatureText != 'V' && displayFeatureText ? ParentData[index]?.FeatureText?.FeatureText : ''}
                    </p>
                    {/* <p>{ParentData[index]?.Description}</p> */}
                  </div>
                </Default>
              </>
            ) : null}

            {sourceComponent == 'Feature' && show && player ? (
              <>
                <div className={classNames(styles.Body, type == 'LightBox' ? styles.LightBoxBody : null)}>
                  <Default>
                    <ArrowLeft className={classNames(styles.Arrow, styles.Left)} onClick={prev} />
                  </Default>

                  {ParentData[index]?.FeatureText?.ImageType == 'I' ? (
                    <DynamicImage
                      imgSrc={`/api/static/media/byFile/Products/${
                        ParentData[index]?.InheritProductCode ? ParentData[index]?.InheritProductCode : productCode
                      }/Feature/${ParentData[index]?.FeatureNumber}_N_N_N_I_1366x768.JPG`}
                      sizes={'1366x768'}
                      className={styles.FImage}
                    />
                  ) : ParentData[index]?.FeatureText?.ImageType == 'V' ? (
                    <ReactPlayer
                      url={ParentData[index]?.FeatureText?.Destination ? ParentData[index]?.FeatureText?.Destination : `/api/static/media/byFile/Products/${
                        ParentData[index]?.InheritProductCode ? ParentData[index]?.InheritProductCode : productCode
                      }/feature/${ParentData[index]?.FeatureNumber}_N_L_N_V_640X360.mp4`}
                      controls={true}
                      width="100%"
                      height={isMobileOpen ? "100%" : "60vh"}
                      playing={true}
                      muted={true}
                    />
                  ) : null}

                  <Default>
                    <ArrowRight className={classNames(styles.Arrow, styles.Right)} onClick={next} />
                  </Default>
                </div>

                <div className={styles.TextContent}>
                  <h4 className={styles.MSubTitle}>{ParentData[index]?.FeatureText?.Title}</h4>
                  <p>{ParentData[index]?.FeatureText?.FeatureText != 'V' && displayFeatureText ? ParentData[index]?.FeatureText?.FeatureText : ''}</p>
                </div>
              </>
            ) : null}

            {sourceComponent == 'CompatibleImplement' && (
              <>
                <DynamicImage
                  imgSrc={`/api/static/media/en/products/${ParentData[index]?.Product.ProductCode}/studio/1/n/n/640x360/i`}
                  sizes={'640x360'}
                  className={styles.Image}
                  alt={ParentData[index]?.Product.Title}
                />
                <div className={styles.TextContent}>
                  <h4 className={styles.CompTitle}>{ParentData[index]?.Product?.Title}</h4>
                  <h6 className={styles.CompSubtitle}>{ParentData[index]?.Product.SubTitle}</h6>
                  <p>{ParentData[index]?.Product.SmallDescription}</p>
                  {/* <p>{ParentData[index]?.Description}</p> */}
                </div>
              </>
            )}
            {children}
            {type == 'DecisionModal' && (
              <div className={styles.BtnSection}>
                <Button className={classNames(styles.Button, styles.Cancel)} onClick={onHide}>
                  Cancel
                </Button>
                <Button className={classNames(styles.Button, styles.Confirm)} animated>
                  Confirm
                </Button>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
