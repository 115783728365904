import { isEmpty } from 'lodash';
import promiseMemoize from 'promise-memoize';
import axios, { apiClient } from '@/http-client';
import { WEBSITE_URL } from '@/app-configuration';

let backup = [];

export const imageExists = async (event, size) => {
  event.target.onerror = null;
  if (!isEmpty(backup)) {
    let fileUrl = backup.find((file) => file.name.toLowerCase().match(size?.toLowerCase()));
    let src = fileUrl?.name ? fileUrl.name : backup[0].name;
    event.target.src = `/api/static/media/byFile${src}`;
  }
};

export const fetchBackupImg = async () => {
  try {
    const {
      data: { Files }
    } = await apiClient.get(`/api/mediaList/Default/Backup?functionId=-1`);
    backup = Files;
    return Files;
  } catch (error) {
    console.error('Error loading getBackupImg image', error?.message);
    throw error;
  }
};

export const getBackupImg = promiseMemoize(fetchBackupImg, { maxAge: 60000 });

export async function BackgroundHeroCheckup(productCode, seasonalittypescripty, size) {
  try {
    const url = `/api/static/media/byfile/products/${productCode}/BackgroundHero/1_N_N_${seasonalittypescripty}_I_${size}.jpg`;
    await axios.get(`${WEBSITE_URL}${url}`);
    return url;
  } catch (_error) {
    try {
      const url = `/api/static/media/byfile/products/${productCode}/BackgroundHero/1_N_N_N_I_${size}.jpg`;
      await axios.get(`${WEBSITE_URL}${url}`);
      return url;
    } catch (error) {
      console.error('Error loading BackgroundHeroCheckup image', error?.message);
      throw error;
    }
  }
}

export async function PostBgHeroCheckup(id, size) {
  if (!id) throw new Error('ID not provided in PostBgHeroCheckup');
  try {
    const url = `/api/static/media/byfile/Posts/${id}/Post/1_N_N_N_I_${size}.jpg`;
    await axios.get(`${WEBSITE_URL}${url}`);
    return url;
  } catch (error) {
    console.error(`Error loading PostBgHeroCheckup image with ID: ${id}`, error?.message);
    throw error;
  }
}

export async function StudioCheckup(productCode, size) {
  if (!productCode) throw new Error('Product Code not provided in StudioCheckup');
  try {
    const url = `/api/static/media/byfile/products/${productCode}/Studio/1_N_N_N_I_${size}.png`;
    await axios.get(`${WEBSITE_URL}${url}`);
    return url;
  } catch (error) {
    console.error('Error loading StudioCheckup image', error?.message);
    return '/api/static/media/byfile/Default/Backup/1_N_L_N_I_640X360.jpg';
  }
}
