import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { autop } from '@wordpress/autop';

import API from '@utils/api';
import {
  addProductCompareCodeAction,
  removeProductCompareCodeAction,
  addProductCompareAction,
} from '@stores/compareProduct/actions';
import {
  compareProductSelector,
  getCompareProductsSelector,
} from '@stores/compareProduct/selectors';
import { CheckboxInput } from '@components';
import { getLanguageId } from '@helpers/language';
import { translatePrice } from '@helpers/product';
import { getBackupImg } from '@helpers/dataExists';
import styles from './ProductTile.module.scss';

const DynamicImage = dynamic(() => import('@components/DynamicImage'), {
  ssr: false
});

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const ProductTile = ({
  className,
  title,
  features,
  price,
  label,
  productCode,
  compareCheckboxShow,
  subTitle,
  route,
  productType,
  smallDescription,
  sellPriceText,
  sellPrice,
  hasStudio,
  hasbgHero,
  DarkMode,
  accessories,
  featureTextEnabled,
  featureTitleEnabled,
  imageAltEnabled,
  linkOutEnabled
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const checkedProducts = useSelector(compareProductSelector);
  const allSelectedProducts = useSelector(getCompareProductsSelector);

  const { locale } = router;
  let hideCompareBtn = ['/dealer/select-a-dealer', '/dealer/contact-a-dealer'];
  const featureTexts = features?.map((item) => {
    return Array.isArray(item.FeatureText)
      ? item.FeatureText.find(({ LanguageId }) => parseInt(LanguageId) === getLanguageId(locale))
      : item.FeatureText;
  });

  // Change on Checkbox component
  const checkBoxEventHandler = async (e) => {
    e.preventDefault();
    const PCode = e.target.value;

    if (e.target.checked) {
      if (checkedProducts.length < 2) {
        if (!allSelectedProducts?.map((m) => m.ProductCode).includes(PCode)) {
          let productObj = await API.post('/api/products', {
            code: PCode,
            locale: locale
          });
          dispatch(addProductCompareAction(productObj));
        }
        if (!checkedProducts.includes(PCode)) {
          dispatch(addProductCompareCodeAction(PCode));
        }
      }
    } else {
      dispatch(removeProductCompareCodeAction(PCode));
    }
  };

  useEffect(async () => {
    await getBackupImg();
  });

  return (
    <div
      className={classNames(
        styles.Tile,
        DarkMode ? styles.DarkMode : null,
        accessories ? styles.AccessoriesTile : null,
        hasStudio ? styles.IsStudio : null
      )}
    >
      <ConditionalWrapper
        condition={linkOutEnabled}
        wrapper={(children) => (
          <a className={classNames(className)} href={`${locale=='fr'? '/fr': ''}/products/${productCode}`}>
            {children}
          </a>
        )}
      >
        <div
          className={classNames(styles.ImgBox, {
            [styles.ImageContainer]: productType === 5 || productType === 11 || hasStudio ? true : false,
            [styles.StudioStyle]: hasStudio ? true : false
          })}
        >
          {label && (
            <span className={styles.LabelContainer}>
              {label.map((lb,index) => (
                <label key={index} className={styles.Label}>{lb}</label>
              ))}
            </span>
          )}
          {productType !== 5 && productType !== 11 && hasStudio ? (
            <DynamicImage
              imgSrc={`/api/static/media/byfile/Products/${productCode}/Studio/1_N_N_N_I_640x640.png`}
              sizes={'640x640'}
              className={styles.Image}
              alt={title}
            />
          ) : productType !== 5 && productType !== 11 && hasbgHero ? (
            <DynamicImage
              imgSrc={`/api/static/media/byfile/products/${productCode}/BackgroundHero/1_N_N_N_I_640x360.jpg`}
              sizes={'640x360'}
              alt={imageAltEnabled ? title : null}
              className={styles.TopImage}
            />
          ) : accessories && hasStudio ? (
            <DynamicImage
              imgSrc={`/api/static/media/byfile/products/${productCode}/Studio/1_N_N_N_I_640X640.png`}
              sizes={'640x360'}
              className={styles.Image}
              alt={title}
            />
          ) : hasStudio ? (
            <DynamicImage
              imgSrc={`/api/static/media/byfile/Products/${productCode}/Studio/1_N_N_N_I_640x640.png`}
              sizes={'640x640'}
              className={styles.Image}
              alt={title}
            />
          ) : hasbgHero ? (
            <DynamicImage
              imgSrc={`/api/static/media/byfile/products/${productCode}/BackgroundHero/1_N_N_N_I_640X360.JPG`}
              sizes={'640x360'}
              className={styles.Image}
              alt={title}
            />
          ) : null}
        </div>
        <div className={styles.Content}>
          <div className={styles.Info}>
            <h3 className={styles.Title}>{title}</h3>
            {subTitle && <h6 className={styles.SubTitle}>{subTitle}</h6>}

            {/* equipment page */}
            {smallDescription && (
              <div
                className={styles.SmallDescription}
                dangerouslySetInnerHTML={{
                  __html: autop(smallDescription)
                }}
              />
            )}
            {/* equipment sub page - Tractor */}
            {productType !== 5 && productType !== 11 && sellPriceText!="" && (
              <ul className={classNames(styles.catQuickFeatures)}>
                <li className={styles.price}>
                  <b>
                    {sellPriceText && sellPrice > 0 ? sellPriceText : null} {sellPrice > 0 ? translatePrice(sellPrice, locale) : null}
                  </b>
                </li>
              </ul>
            )}
            {features?.length > 0 && featureTextEnabled && featureTitleEnabled && (
              <ul className={productType !== 5 && productType !== 11 ? styles.catQuickFeatures : styles.QuickFeatures}>
                {featureTexts.map(({ QFId, FeatureTitle, FeatureText }) => (
                  <li className={styles.QuickFeature} key={QFId}>
                    <b>{FeatureTitle}</b> {FeatureText}
                  </li>
                ))}
              </ul>
            )}
            {(productType == 5 || (productType == 11 && !DarkMode)) && (
              <div className={styles.Price}>{sellPriceText && sellPrice > 0 ? sellPriceText : null} {sellPrice > 0 ? translatePrice(sellPrice, locale) : null}</div>
            )}
            {/* {(productType !== 2 && productType !== 1 && !DarkMode) && (
                <div className={styles.Price}>{sellPrice ? "$" : null}{sellPrice}</div>
              )} */}
          </div>
        </div>
      </ConditionalWrapper>

      {(productType == 5 || productType == 11) && compareCheckboxShow && !hideCompareBtn.includes(route) && (
        <CheckboxInput
          className={styles.Compare}
          onChange={checkBoxEventHandler}
          title="Compare"
          name="isCheckedProduct"
          value={productCode}
          id={productCode}
          DarkMode={DarkMode}
          // disabled={checkedProducts.length >= 3 ? true : false}
          checked={checkedProducts.indexOf(productCode) !== -1}
        />
      )}
    </div>
  );
};

export default ProductTile;
